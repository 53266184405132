import BaseService from '@/services/BaseService'

class Categories extends BaseService {
  constructor() {
    super()
  }
  async getCategories(opts = {}) {
    this.endPoint = 'api/v2/categories'
    return this.get(opts)
  }
  async getSubCategories(id, opts = {}) {
    this.endPoint = `api/v2/categories/${id}`
    return this.get(opts).then(response => {
      return response
    })
  }
  async getCategoryById(id) {
    this.endPoint = `api/v2/categories/${id}?include=rules`
    return this.get(id)
  }
  async createCategories(payload: any) {
    this.endPoint = 'api/v2/categories'
    return this.post(payload).then(response => {
      return response
    })
  }
  async editCategories(payload, id) {
    this.endPoint = 'api/v2/categories'
    return this.putOne(id, payload)
  }
  async deleteCategory(id){
    this.endPoint = 'api/v2/categories'
    return this.delete(id)
    // this.endPoint = `api/v2/categories/${id}`
    // return this.delete(id).then(response => {
    //   return response.data
    // })
  }
  async getTotalProductsCategories(id){
    this.endPoint = `/api/v2/categories/${id}/total-products`
    return this.get(id)
  }
}
export default new Categories()
