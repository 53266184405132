import BaseService from '@/services/BaseService'


class ProductService extends BaseService {
  constructor() {
    super()
  }
  getProductsLists(opts = {}) {
    this.endPoint = 'api/v2/products'
    return this.get(opts).then(response => {
      return response.data
    })
  }
  getUncategorizedProductsLists(id, opts = {}) {
    this.endPoint = `api/v2/products?uncategorized_child=${id}`
    return this.get(opts).then(response => {
      return response.data
    })
  }
  getOneProduct(id: any, params = {}) {
    this.endPoint = 'api/v2/products'
    return this.getOne(id, params).then(response => {
      return response.data
    })
  }
  async getOriginalCategory(opts = {}) {
    this.endPoint = '/api/v2/source-categories'
    return await this.get(opts).then(response => {
      return response.data
    })
  }
  createNewProduct(data: any) {
    // manual-products
    this.endPoint = 'api/v2/products'
    return this.post(data).then(response => {
      return response
    })
  }
  updateProduct(id: any, data: any, params = {}) {
    this.endPoint = 'api/v2/products'
    return this.putOne(id, data, params).then(response => {
      return response.data
    })
  }
  updateTitleProduct(data: any) {
    this.endPoint = 'api/v2/products/update-bulk'
    return this.put(data).then(response => {
      return response.data
    })
  }
  deleteProduct(id: any) {
    this.endPoint = 'api/v2/products'
    return this.delete(id).then(response => {
      return response
    })
  }
  deleteProducts(data: any) {
    this.endPoint = 'api/v2/products/bulk-delete'
    return this.bulkDelete_productList(data).then(response => {
      return response
    })
  }
  syncProduct(id) {
    this.endPoint = `api/v2/products/sync-product/${id}`
    return this.post(id).then(response => {
      return response
    })
  }
  getTopLevelCategories(opts = {}) {
    this.endPoint = 'api/v2/categories'
    return this.get(opts).then(response => {
      return response
    })
  }
  getLowLevelCategories(id, opts = {}) {
    this.endPoint = `api/v2/categories?filter[parent_id][is]=${id}`
    return this.get(opts).then(response => {
      return response
    })
  }
  setMainImage(id, data: any) {
    this.endPoint = `api/v2/products/${id}/image/set-main`
    return this.post(data).then(response => {
      return response
    })
  }
  setOptionValueImage(data: any, params = {}) {
    this.endPoint = '/api/v2/products/attribute/image'
    return this.post(data, params).then(response => {
      return response
    })
  }
  setSkuImage(data: any, params = {}) {
    this.endPoint = 'api/v2/products/variant/image'
    return this.post(data, params).then(response => {
      return response
    })
  }
  updateBulkCategoryAndTag(data: any) {
    this.endPoint = 'api/v2/products/update-bulk'
    return this.put(data).then(response => {
      return response
    })
  }

  getProductCollectors(id, opts = {}) {
    this.endPoint = `api/v2/products/${id}/collectors`
    return this.get(opts).then(response => {
      return response
    })
  }

  cloneProduct(id) {
    this.endPoint = 'api/v2/products/clone'
    return this.post(id).then(response => {
      return response
    })
  }
}
export default new ProductService()
