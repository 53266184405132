import BaseService from '@/services/BaseService'

class Rules extends BaseService {
  constructor() {
    super()
  }
  async putCategoryRules(payload, ruleId) {
    this.endPoint = 'api/v2/category-rules'
    return this.putOne(ruleId, payload)
  }
  async getCategoryRules(opts={}) {
    this.endPoint = 'api/v2/category-rules?include=categories'
    return this.get(opts)
  }
  async getCategoryRulesById(id) {
    this.endPoint = `api/v2/category-rules/${id}?include=rules`
    return this.get(id)
  }
  /* Category Rules */
  async getRules(id) {
    this.endPoint = `api/v2/categories/${id}?include=rules`
    return this.get(id)
  }
  async getParent(id) {
    this.endPoint = `api/v2/categories/${id}?include=parent`
    return this.get(id)
  }
  // // Update Rules
  // async updateCategoryRule(payload, ruleId){
  //   this.endPoint = 'api/v2/category-rules'
  //   return this.putOne(ruleId, payload)
  // }

  async saveRule(payload) {
    this.endPoint = 'api/v2/category-rules'
    return this.post(payload)
  }
  // Delete Category
  async deleteCategoryRules(ruleId) {
    this.endPoint = 'api/v2/category-rules'
    return this.delete(ruleId)
  }
  /* Product Rules */
  async getProductRules(opts = {}) {
    this.endPoint = 'api/v2/product-rules'
    return this.get(opts)
  }
  async getOneProductRules(ruleId, opts = {}) {
    this.endPoint = `api/v2/product-rules/${ruleId}`
    return this.get(opts)
  }
  async saveProductRule(payload, ruleId = '') {
    this.endPoint = 'api/v2/product-rules'
    if (ruleId === ''){
      return this.post(payload)
    } else {
      return this.putOne(ruleId,payload)
    }
  }
  async attachProductsToRule(ruleId, payload) {
    this.endPoint = `api/v2/product-rules/${ruleId}/attach-products`
    return this.post(payload)
  }
  async detachProductsToRule(ruleId, payload) {
    this.endPoint = `api/v2/product-rules/${ruleId}/attach-products`
    return this.post(payload)
  }
}
export default new Rules()
